import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { NavLink, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { connect } from 'react-redux';
import { signInToFirebase } from '../../Store/Actions/AuthenticationActions';
import { useState } from 'react';

let isButtonEnabled = false;

function SignIn({ signInToAvail }) {
    const [isErrorVisible, setIsErrorVisible] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    setTimeout(function () {
        setIsErrorVisible(false);
    }, 7000);

    isButtonEnabled = true;

    const handleSubmit = (event) => {
        event.preventDefault();
        // event.stopPropagation();
        const data = new FormData(event.currentTarget);
        signInToAvail(data.get('email'), data.get('password'));
    };

    return (
        <>
            <Typography component="h1" variant="h5">Sign in</Typography>
            {
                isErrorVisible && (
                    <Stack sx={{ width: '100%' }} spacing={2}>
                        <Alert severity="error">{errorMsg}</Alert>
                    </Stack>
                )
            }
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                />
                <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me"
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    disabled={!isButtonEnabled}
                    sx={{ mt: 3, mb: 2 }}
                >
                    Sign In
                </Button>
                <Grid container>
                    <Grid item xs>
                        <NavLink to="/reset-assword" variant="body">
                            Forgot password?
                        </NavLink>
                    </Grid>
                    {/*
                    <Grid item>
                      <Link href="#" variant="body2">
                        {"Don't have an account? Sign Up"}
                      </Link>
                    </Grid>
                    */}
                </Grid>
            </Box>
        </>
    );
}

SignIn.propTypes = {};

const dispatchSignInProps = (dispatch) => {
    return {
        signInToAvail: (username, password) => dispatch(signInToFirebase(username, password)),
    };
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, dispatchSignInProps)(SignIn);
