import { combineReducers } from 'redux';
import authReducer from './AuthReducer';


// TODO delete the location reducer
export function makeRootReducer(asyncReducers) {
    return combineReducers({
        auth: authReducer,
        ...asyncReducers
    });
}


export default makeRootReducer;