import React, { useEffect, useState } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { downloadPdf, generateReport, getOrganizationInfo } from '../../../../Services/Search.services';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import { toast } from 'react-toastify';
import enGB from 'date-fns/locale/en-gb';
import { Filters } from '../../../Shared/Filters/Filters';
import { format } from 'date-fns';
import { dateFormat } from '../../../../Constants/Date.constant';
import { Header } from '../../../Shared/Header/Header';

const GridContainer = styled(Grid)(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

export function OrgReportsTab({ orgName }) {
    const [initialDates, setInitialDates] = useState({ startDate: null, endDate: null });
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [urlForZip, setUrlForZip] = useState(null);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [isReportReady, setIsReportReady] = useState(false);
    const buttonSx = {
        ...(isSuccess && {
            bgcolor: green[500],
            '&:hover': {
                bgcolor: green[700],
            },
        }),
    };

    const calculateFilterInitialDates = (dayOfMonthInvoicing) => {
        const newDate = new Date();
        newDate.setDate(dayOfMonthInvoicing);

        if (dayOfMonthInvoicing > newDate.getDay()) {
            const previousMonth = newDate.getMonth() - 1;
            newDate.setMonth(previousMonth);
        }

        const endDate = new Date(newDate);
        const previousMonth = newDate.getMonth() - 1;
        newDate.setMonth(previousMonth);

        const startDate = new Date(newDate);
        setInitialDates({ startDate, endDate });

        const formattedStartDate = format(startDate, dateFormat);
        const formattedEndDate = format(endDate, dateFormat);
        setStartDate(formattedStartDate);
        setEndDate(formattedEndDate);
    };

    const getFilterFormValue = (filterValues) => {
        const startDate = filterValues.startDate;
        const endDate = filterValues.endDate;
        const formattedStartDate = startDate && format(startDate, dateFormat);
        const formattedEndDate = endDate && format(endDate, dateFormat);

        setStartDate(formattedStartDate);
        setEndDate(formattedEndDate);
        // setGeneratePdf(filterValues.generatePdf);
        setUrlForZip(null);
        setIsReportReady(false);
        setIsSuccess(false);
        setIsButtonLoading(false);
    };

    useEffect(() => {
        getOrganizationInfo(orgName)
            .then((response) => {
                const dayOfMonthInvoicing = response?.dayOfMonthInvoicing ?? '';
                calculateFilterInitialDates(dayOfMonthInvoicing);
            });
    }, [orgName]);

    const makeParams = () => {
        let urlParam = '';
        if (startDate) {
            urlParam += '&startDate=' + startDate;
        }
        if (endDate) {
            urlParam += '&endDate=' + endDate;
        }
        // if generatePdf exists
        // if (typeof generatePdf !== 'undefined') {
        //     urlParam += '&generatePdf=' + generatePdf;
        // }
        if (urlParam !== '') {
            urlParam = urlParam.substring(1);
        }
        return urlParam;
    };

    const checkIfReportIsReady = (fileLocation, orgName, negativeResultCallback) => {
        downloadPdf(fileLocation, orgName)
            .then((response) => {
                if (response.status === 200) {
                    setIsSuccess(true);
                    setIsButtonLoading(false);
                    setIsReportReady(true);
                } else if (response.status === 202) {
                    setIsSuccess(false);
                    setIsButtonLoading(true);
                    setIsReportReady(false);

                    negativeResultCallback?.();
                }
            })
            .catch((error) => {
                if (error.response.status === 404) {
                    setIsSuccess(false);
                    setIsButtonLoading(false);
                    setIsReportReady(false);

                    toast.error('Not found.');
                } else if (error.response.status === 500) {
                    setIsSuccess(false);
                    setIsButtonLoading(false);
                    setIsReportReady(false);

                    toast.error('Server error.');
                }
            });
    };

    const handleButtonClick = () => {
        if (isReportReady) {
            if (urlForZip) {
                setIsButtonLoading(true);
                downloadPdf(urlForZip)
                    .then((response) => {
                        if (response.status === 200) {
                            const url = window.URL.createObjectURL(new Blob([response.data]));
                            const link = document.createElement('a');
                            link.href = url;
                            //new Date().toLocaleString()
                            link.setAttribute('download', orgName + '.zip'); //or any other extension
                            // change the file name
                            document.body.appendChild(link);
                            link.click();
                        } else if (response.status === 202) {
                            const url = response.headers['content-location'];
                            const link = document.createElement('a');
                            link.href = url;
                            document.body.appendChild(link);
                            link.click();
                        } else {
                            alert('Unknown Error');
                        }
                    })
                    .finally(() => setIsButtonLoading(false));
            } else {
                setIsReportReady(false);
                setIsSuccess(false);
                setIsButtonLoading(false);
            }
        } else {
            if (isButtonLoading) {
                return;
            }

            setIsSuccess(false);
            setIsButtonLoading(true);
            let urlParam = makeParams();

            generateReport(orgName, urlParam)
                .then((res) => {
                    const fileLocation = res.headers['content-location'];
                    setUrlForZip(fileLocation);

                    const repeatIfReportIsNotReady = () => {
                        setTimeout(
                            () => checkIfReportIsReady(fileLocation, orgName, repeatIfReportIsNotReady),
                            1000
                        );
                    };
                    repeatIfReportIsNotReady();
                });
        }
    };

    return (
        <Box sx={{ flexGrow: 1, ml: 2, mt: 2, mb: 2 }}>
            <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={enGB}
            >
                <Grid container spacing={2}>
                    <div className="header">
                        <Header
                            title="Monthly Reports"
                            filters={(
                                <Filters
                                    key={initialDates.startDate}
                                    isDateRange
                                    onFiltersChange={getFilterFormValue}
                                    initialValues={initialDates}
                                />
                            )}
                        />
                    </div>
                    <Grid item xs={12} sm={12} md={12} ls={12} xl={12}>
                        <Grid container spacing={3} sx={{ display: 'flex', flexDirection: 'row' }}>
                            <GridContainer item xs={12} sm={12} md={12} ls={12} xl={12}>
                                <Box sx={{ m: 1, position: 'relative' }}>
                                    <Button
                                        variant="contained"
                                        sx={buttonSx}
                                        disabled={isButtonLoading}
                                        onClick={handleButtonClick}
                                    >
                                        {isReportReady ? 'Download Report' : 'Generate Report'}
                                    </Button>
                                    {isButtonLoading && (
                                        <CircularProgress
                                            size={24}
                                            sx={{
                                                color: green[500],
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }}
                                        />
                                    )}
                                </Box>
                            </GridContainer>
                        </Grid>
                    </Grid>
                </Grid>
            </LocalizationProvider>
        </Box>
    );
}
