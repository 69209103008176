import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { signOutFromFirebase } from '../../Store/Actions/AuthenticationActions';
import { connect } from 'react-redux';
import { styled } from '@mui/system';
import { useState } from 'react';

const ToolbarContainer = styled(Toolbar)(({ theme }) => {
    return {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'space-between',
    };
});

function TopNavBar({ signOut, auth }) {
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleMenuLink = (route) => () => {
        navigate(route);
    };
    let navigate = useNavigate();
    const handleLogout = () => {
        // debugger;
        signOut();
    };

    const handleProfile = () => {

    };
    const handleLogoClick = () => {
        window.location.reload();
        navigate('/');
    };

    const isAdmin = auth.isAdmin;

    const pages = [{ id: 1, name: 'Search', route: '/' },
        // {id: 2, name: 'Dashboard', route: '/dashboard'},
        // {id: 3, name: 'Filed Documents', route: '/filed-documents'},
        // {id: 2, name: 'Add Organisation', route: '/add-organisation'},
    ];
    if (isAdmin) {
        pages.push({ id: 2, name: 'Add Organisation', route: '/add-organisation' });
        pages.push({ id: 4, name: 'Service Status', route: '/service-status' });
        pages.push({ id: 5, name: 'Check Organisations', route: '/check-organisations' });
        pages.push({ id: 6, name: 'Overall Statistics', route: '/overall-statistics' });
        pages.push({ id: 7, name: 'Reports', route: '/reports' });
        pages.push({ id: 8, name: 'Feature Flags', route: '/feature-flags' });
    }

    const settings = [{ id: 1, label: 'Profile', click: handleProfile },
        { id: 2, label: 'Logout', click: handleLogout },
    ];

    return (

        <AppBar position="fixed" style={{ zIndex: 998 }}>
            <Container maxWidth="xl">
                <ToolbarContainer disableGutters>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ mr: 3, ml: 2, display: { xs: 'none', md: 'flex' } }}
                    >
                        {
                            <img
                                src={process.env.PUBLIC_URL + '/images/white-logo.svg'}
                                style={{ height: '37px', cursor: 'pointer' }}
                                alt=""
                                onClick={handleLogoClick}
                            />
                        }
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >

                            {pages.map((page) => (
                                <MenuItem key={page.id} onClick={handleMenuLink(page.route)}>
                                    <Typography textAlign="center">{page.name}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
                    >
                        <img
                            src={process.env.PUBLIC_URL + '/images/white-logo.svg'}
                            style={{ height: '37px' }}
                            alt=""
                        />
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page) => (
                            <Button
                                key={page.id}
                                onClick={handleMenuLink(page.route)}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                {page.name}
                            </Button>
                        ))}
                    </Box>
                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar alt="Avail" src={process.env.PUBLIC_URL + '/images/girl-from-hole.webp'} />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            {settings.map((setting) => (
                                <MenuItem key={setting.id} onClick={setting.click}>
                                    <Typography textAlign="center">{setting.label}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </ToolbarContainer>
            </Container>
        </AppBar>
    );
}

TopNavBar.propTypes = {};

const dispatchSignOutFromFirebase = (dispatch) => ({
    signOut: () => dispatch(signOutFromFirebase()),
});

const mapStateToProps = (state) => ({
    auth: state.auth,

});

export default connect(mapStateToProps, dispatchSignOutFromFirebase)(TopNavBar);
