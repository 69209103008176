import React from 'react';
import TopNavBar from './TopNavBar';


export default function NavbarComponent({ handleDrawerToggle }) {

    return (
        <TopNavBar />
    );
}
