import React, { useMemo, useState } from 'react';
import { MaterialReactTable, MRT_EditActionButtons, useMaterialReactTable } from 'material-react-table';
import { Box, Button, CircularProgress, IconButton, Tooltip, Typography } from '@mui/material';
import { QueryClient, QueryClientProvider, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
    createFeatureFlag,
    deleteFeatureFlag,
    getFeatureFlags,
    updateFeatureFlag,
} from '../../../Services/Search.services';
import Checkbox from '@mui/material/Checkbox';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import ConfirmationModal from '../../Shared/ConfirmationModal';

function FeatureFlagsTable() {
    const [validationErrors, setValidationErrors] = useState({});
    //keep track of rows that have been edited
    const [editedFeatureFlag, setEditedFeatureFlag] = useState({});
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 5,
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [globalFilter, setGlobalFilter] = useState('');

    const openDeleteFeatureFlagConfirmModal = (row) => {
        setSelectedRow(row);
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        setSelectedRow(null);
    };

    const handleModalConfirm = async () => {
        if (selectedRow) {
            try {
                await deleteFeatureFlagData({ featureFlagId: selectedRow.original.id });
                // Handle success
            } catch (error) {
                // Handle error
            }
        }
        handleModalClose();
    };

    const columns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: 'Id',
                enableEditing: false,
                size: 80,
            },
            {
                accessorKey: 'name',
                header: 'Name',
                muiEditTextFieldProps: ({ cell }) => ({
                    type: 'text',
                    required: true,
                    error: !!validationErrors?.[cell.id],
                    helperText: validationErrors?.[cell.id],
                    //store edited user in state to be saved later
                    onBlur: (event) => {
                        const newValue = event.currentTarget.value;
                        const validationError = !validateRequired(newValue)
                            ? 'Required'
                            : undefined;
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: validationError,
                        });
                    },
                }),
            },
            {
                accessorKey: 'user_name',
                header: 'User Name',
                muiEditTextFieldProps: ({ cell }) => ({
                    type: 'text',
                    required: true,
                    error: !!validationErrors?.[cell.id],
                    helperText: validationErrors?.[cell.id],
                    //store edited user in state to be saved later
                    onBlur: (event) => {
                        const newValue = event.currentTarget.value;
                        const validationError = newValue?.length ? undefined : 'Required';

                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: validationError,
                        });
                    },
                }),
            },
            {
                accessorKey: 'organisation',
                header: 'Organisation',
                muiEditTextFieldProps: ({ cell, row }) => ({
                    type: 'email',
                    required: true,
                    error: !!validationErrors?.[cell.id],
                    helperText: validationErrors?.[cell.id],
                    //store edited user in state to be saved later
                    onBlur: (event) => {
                        const validationError = !validateRequired(event.currentTarget.value)
                            ? 'Incorrect Email Format'
                            : undefined;
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: validationError,
                        });
                    },
                }),
            },
            {
                accessorKey: 'is_enabled',
                header: 'Is Enabled',
                enableGlobalFilter: false,
                editSelectOptions: [
                    { label: 'true', value: true },
                    { label: 'false', value: false },
                ],
                editVariant: 'select',
                Cell: ({ cell }) => (
                    <Checkbox
                        checked={cell.getValue() === true}
                        readOnly // Ensure the checkbox is read-only in display mode
                    />
                ),
                enableEditing: true,
                onBlur: (event, cell) => {
                    // Assuming the event is triggered with the correct cell reference
                    const newValue = cell.getValue(); // Get the current cell value
                    const validationError = validateBooleanRequired(newValue)
                        ? 'Required'
                        : undefined;

                    setValidationErrors((prevErrors) => ({
                        ...prevErrors,
                        [cell.id]: validationError,
                    }));
                },
            },
            {
                accessorKey: 'message',
                header: 'Message',
                muiEditTextFieldProps: ({ cell, row }) => ({
                    type: 'message',
                    required: false,
                    error: !!validationErrors?.[cell.id],
                    helperText: validationErrors?.[cell.id],
                    onBlur: (event) => {
                        const validationError = !validateMessage(event.currentTarget.value)
                            ? 'Incorrect Message Format'
                            : undefined;
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: validationError,
                        });
                        setEditedFeatureFlag({ ...editedFeatureFlag, [row.id]: row.original });
                    },
                }),
            },
        ],
        [editedFeatureFlag, validationErrors],
    );

    //call CREATE hook
    const { mutateAsync: createFeatureFlagData, isPending: isCreatingFeatureFlag } =
        useCreateFeatureFlag(pagination, globalFilter);
    //call READ hook
    const {
        data: featureFlagsData = [],
        isError: isLoadingUsersError,
        isFetching: isFetchingUsers,
        isLoading: isLoadingUsers,
    } = useFeatureFlags(pagination, globalFilter);

    //call UPDATE hook
    const { mutateAsync: updateFeatureFlagData, isPending: isUpdatingFeatureFlag } =
        useUpdateFeatureFlag(pagination, globalFilter);

    //call DELETE hook
    const { mutateAsync: deleteFeatureFlagData, isPending: isDeletingFeatureFlag } =
        useDeleteFeatureFlag(pagination, globalFilter);

    const handleCreateFeatureFlag = async ({ values, table }) => {
        try {
            await createFeatureFlagData({ featureFlagData: values });
            table.setCreatingRow(null); // Exit creating mode
            // Optional: Show success message
        } catch (error) {
            // Handle error, e.g., show an error message
        }
    };

    const handleSaveFeatureFlags = async ({ values, table }) => {
        const isErrorExists = Object.values(validationErrors)
            .some((error) => !!error);

        if (isErrorExists) return;

        try {
            // Assuming updateFeatureFlag is the mutation function from useUpdateFeatureFlag hook
            // await updateFeatureFlag(Object.values(editedFeatureFlag));

            await updateFeatureFlagData({ featureFlagId: values.id, requestData: values });
            table.setEditingRow(null);
            // setEditedFeatureFlag({}); // Reset the edited feature flags

            // Optional: Show success message or handle UI updates
        } catch (error) {
            console.error('handleSaveFeatureFlags caused an error', error);
            // Handle error
        }
    };

    const table = useMaterialReactTable({
        columns,
        data: featureFlagsData.data?.items ?? [],
        createDisplayMode: 'modal', // ('modal', and 'custom' are also available)
        editDisplayMode: 'modal', // ('modal', 'row', 'cell', and 'custom' are also
        // editDisplayMode: 'table', // ('modal', 'row', 'cell', and 'custom' are also
        enableEditing: true,
        enableRowActions: true,
        positionActionsColumn: 'last',
        pageCount: featureFlagsData?.data?.total ? Math.ceil(featureFlagsData?.data?.total / pagination.pageSize) : 0,
        rowCount: featureFlagsData?.data?.total ?? 0,
        onPaginationChange: setPagination,
        manualPagination: true,
        getRowId: (row) => row.id,
        muiToolbarAlertBannerProps: isLoadingUsersError
            ? {
                color: 'error',
                children: 'Error loading data',
            }
            : undefined,
        muiTableContainerProps: {
            sx: {
                minHeight: '500px',
            },
        },
        muiPaginationProps: {},
        manualFiltering: true, //turn off client-side filtering
        onGlobalFilterChange: setGlobalFilter, //hoist internal global state to your state
        state: {
            pagination,
            globalFilter,
            isLoading: isLoadingUsers,
            isSaving: isCreatingFeatureFlag || isUpdatingFeatureFlag || isDeletingFeatureFlag,
            showAlertBanner: isLoadingUsersError,
            showProgressBars: isFetchingUsers,
        },
        onCreatingRowCancel: () => setValidationErrors({}),
        onCreatingRowSave: handleCreateFeatureFlag,
        onEditingRowCancel: () => setValidationErrors({}),
        onEditingRowSave: handleSaveFeatureFlags,
        renderCreateRowDialogContent: ({ table, row, internalEditComponents }) => (
            <>
                <DialogTitle variant="h5">Create New Flag</DialogTitle>
                <DialogContent
                    sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
                >
                    {internalEditComponents}
                    {' '}
                    {/* or render custom edit components here */}
                </DialogContent>
                <DialogActions>
                    <MRT_EditActionButtons variant="text" table={table} row={row} />
                </DialogActions>
            </>
        ),
        renderEditRowDialogContent: ({ table, row, internalEditComponents }) => (
            <>
                <DialogTitle variant="h5">Edit Flag</DialogTitle>
                <DialogContent
                    sx={{ display: 'flex', flexDirection: 'column', gap: '1.5rem' }}
                >
                    {internalEditComponents}
                    {' '}
                    {/* or render custom edit components here */}
                </DialogContent>
                <DialogActions>
                    <MRT_EditActionButtons variant="text" table={table} row={row} />
                </DialogActions>
            </>
        ),
        renderRowActions: ({ row }) => (
            <Box sx={{ display: 'flex', gap: '1rem' }}>
                <Tooltip title="Edit">
                    <IconButton onClick={() => table.setEditingRow(row)}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                    <IconButton color="error" onClick={() => openDeleteFeatureFlagConfirmModal(row)}>
                        <DeleteIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        ),
        renderBottomToolbarCustomActions: () => (
            <Box sx={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                <Button
                    color="success"
                    variant="contained"
                    onClick={handleSaveFeatureFlags}
                    disabled={
                        Object.keys(editedFeatureFlag).length === 0 ||
                        Object.values(validationErrors)
                            .some((error) => !!error)
                    }
                >
                    {isUpdatingFeatureFlag ? <CircularProgress size={25} /> : 'Save'}
                </Button>
                {Object.values(validationErrors)
                    .some((error) => !!error) && (
                    <Typography color="error">Fix errors before submitting</Typography>
                )}
            </Box>
        ),
        renderTopToolbarCustomActions: ({ table }) => (
            <Button
                variant="contained"
                onClick={() => {
                    table.setCreatingRow(true); //simplest way to open the create row modal with no default values
                    //or you can pass in a row object to set default values with the `createRow` helper function
                    // table.setCreatingRow(
                    //   createRow(table, {
                    //     //optionally pass in default values for the new row, useful for nested data or other complex scenarios
                    //   }),
                    // );
                }}
            >
                Create Feature Flag
            </Button>
        ),
    });

    return (
        <>
            <MaterialReactTable table={table} />
            <ConfirmationModal
                open={isModalOpen}
                handleClose={handleModalClose}
                handleConfirm={handleModalConfirm}
                title="Confirm Deletion"
                content="Are you sure you want to delete this feature flag?"
            />
        </>
    );
}

function useCreateFeatureFlag(pagination, globalFilter) {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ featureFlagData }) => {
            let keys = Object.keys(featureFlagData);
            keys.forEach((key) => {
                if (featureFlagData[key] === undefined || featureFlagData[key] === null || featureFlagData[key] === '') {
                    delete featureFlagData[key];
                }
            });
            const response = await createFeatureFlag(featureFlagData);
            // return response.data; // Assuming the response data is what you want to work with
        },
        onMutate: async (newFeatureFlag) => {
            // Optionally handle optimistic update
            await queryClient.cancelQueries({ queryKey: ['feature-flags', pagination.pageIndex, pagination.pageSize, globalFilter] });
            const previousFeatureFlags = queryClient.getQueryData(['feature-flags', pagination.pageIndex, pagination.pageSize, globalFilter]);
            queryClient.setQueryData(['feature-flags', pagination.pageIndex, pagination.pageSize, globalFilter], (oldQueryData) => {
                return {
                    ...oldQueryData,
                    items: [...oldQueryData.data.items, { ...newFeatureFlag }],
                };
            });

            return { previousFeatureFlags };
        },
        onError: (err, newFeatureFlag, context) => {
            // Rollback on error
            queryClient.setQueryData(['feature-flags', pagination.pageIndex, pagination.pageSize, globalFilter], context.previousFeatureFlags);
        },
        onSettled: () => {
            // Invalidate and refetch
            queryClient.invalidateQueries({ queryKey: ['feature-flags', pagination.pageIndex, pagination.pageSize, globalFilter] });
        },
    });
}

function useFeatureFlags(pagination, globalFilter) {
    return useQuery({
        queryKey: ['feature-flags', pagination.pageIndex, pagination.pageSize, globalFilter],
        queryFn: async () => {
            return await getFeatureFlags(pagination.pageIndex * pagination.pageSize, pagination.pageSize, globalFilter);
        },
        refetchOnWindowFocus: false,
    });
}

function useUpdateFeatureFlag(pagination, globalFilter) {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ featureFlagId, requestData }) => {
            await updateFeatureFlag(featureFlagId, requestData);
        },
        onMutate: async (updatedFeatureFlag) => {
            // Optionally handle optimistic update
            await queryClient.cancelQueries({ queryKey: ['feature-flags', pagination.pageIndex, pagination.pageSize, globalFilter] });
            const previousFeatureFlags = queryClient.getQueryData(['feature-flags', pagination.pageIndex, pagination.pageSize, globalFilter]);
            queryClient.setQueryData(['feature-flags', pagination.pageIndex, pagination.pageSize, globalFilter], (oldQueryData) => {
                // Modify according to your data structure
                return {
                    ...oldQueryData,
                    items: oldQueryData.data.items.map((flag) =>
                        flag.id === updatedFeatureFlag.featureFlagId
                            ? { ...flag, ...updatedFeatureFlag.requestData }
                            : flag,
                    ),
                };
            });

            return { previousFeatureFlags };
        },
        onError: (err, updatedFeatureFlag, context) => {
            // Rollback on error
            queryClient.setQueryData(['feature-flags', pagination.pageIndex, pagination.pageSize, globalFilter], context.previousFeatureFlags);
        },
        onSettled: () => {
            // Invalidate and refetch
            queryClient.invalidateQueries({ queryKey: ['feature-flags', pagination.pageIndex, pagination.pageSize, globalFilter] });
        },
    });
}

function useDeleteFeatureFlag(pagination, globalFilter) {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async ({ featureFlagId }) => {
            await deleteFeatureFlag(featureFlagId);
        },
        onMutate: async (featureFlagId) => {
            // Optionally handle optimistic update
            await queryClient.cancelQueries({ queryKey: ['feature-flags', pagination.pageIndex, pagination.pageSize, globalFilter] });
            const previousFeatureFlags = queryClient.getQueryData(['feature-flags', pagination.pageIndex, pagination.pageSize, globalFilter]);
            queryClient.setQueryData(['feature-flags', pagination.pageIndex, pagination.pageSize, globalFilter], (oldQueryData) => {
                // Modify according to your data structure
                return {
                    ...oldQueryData,
                    items: oldQueryData.data.items.filter((flag) => flag.id !== featureFlagId),
                };
            });

            return { previousFeatureFlags };
        },
        onError: (err, featureFlagId, context) => {
            // Rollback on error
            queryClient.setQueryData(['feature-flags', pagination.pageIndex, pagination.pageSize, globalFilter], context.previousFeatureFlags);
        },
        onSettled: () => {
            // Invalidate and refetch
            queryClient.invalidateQueries({ queryKey: ['feature-flags', pagination.pageIndex, pagination.pageSize, globalFilter] });
        },
    });
}

const queryClient = new QueryClient();

function FeatureFlags() {
    return (
        <QueryClientProvider client={queryClient}>
            <FeatureFlagsTable />
        </QueryClientProvider>
    );
}

const validateBooleanRequired = (value) => typeof value !== 'boolean';

const validateRequired = (value) => !!value.length;

const validateMessage = (value) => !value.length ? true : typeof value === 'string';

FeatureFlags.propTypes = {};
export default FeatureFlags;
