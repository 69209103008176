import SkeletonComponent from '../../SkeletonComponent';
import { AvlTable } from '../Table/Table';
import { Alert, CircularProgress, Stack } from '@mui/material';
import React, { useRef, useState } from 'react';
import { datetimeToFormattedString } from '../../../Utils/Utils';
import Box from '@mui/material/Box';
import DownloadIcon from '@mui/icons-material/Download';
import { green } from '@mui/material/colors';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import InventoryIcon from '@mui/icons-material/Inventory';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { constCheckFolder, initiateFolderGeneration } from '../../../Services/Search.services';
import ChangeEmail from '../ChangeEmail';
import FileInformation from '../FileInformation';
import FolderAudits from '../FolderAudits';
import GenerateFolderReports from '../GenerateFolderReports';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { UrlActionType, UrlButton } from '../UrlButton/UrlButton';
import PropTypes from 'prop-types';
import { AvlModal } from '../AvlModal/AvlModal';

export const Columns = {
    projectName: 'Project Name',
    folderId: 'Folder ID',
    titlesAmount: 'Titles',
    username: 'Username',
    matterNumber: 'Matter Number',
    createdAt: 'Created At',
    lastOpenedAt: 'Last Opened',
    lastUpdatedAt: 'Last Updated',
    download: 'Download',
    changeOwnership: 'Change Ownership',
    inspect: 'Inspect',
    audit: 'Audit',
    generate: 'Generate',
    apps: 'Apps',
};

export function ProjectsTable(
    {
        records,
        isLoading,
        page,
        pageCount,
        onPageChange,
        onRefreshRecords,
        hiddenColumns = [],
        projectsOwnerEmail,
    },
) {
    const myRefs = useRef([]);
    const myLoadingRefs = useRef([]);
    const [isChangeEmailPopupOpen, setIsChangeEmailPopupOpen] = useState(false);
    const [selectedProjectId, setSelectedProjectId] = useState('');
    const [selectedRowEmail, setSelectedRowEmail] = useState('');
    const [isInspectPopupOpen, setIsInspectPopupOpen] = useState(false);
    const [isAuditPopupOpen, setIsAuditPopupOpen] = useState(false);
    const [isGeneratePopupOpen, setIsGeneratePopupOpen] = useState(false);

    const handleCloseChangeEmailPopup = () => setIsChangeEmailPopupOpen(false);

    const handleCloseInspect = () => setIsInspectPopupOpen(false);

    const handleCloseAudit = () => setIsAuditPopupOpen(false);

    const handleCloseGenerate = () => setIsGeneratePopupOpen(false);

    const handleDownload = async (downUrl, projectName, id, index) => {
        myRefs.current[index].style.disabled = true;
        myLoadingRefs.current[index].style.display = 'block';
        myRefs.current[index].style.backgroundColor = 'rgba(25, 118, 210, 0.04)';
        myRefs.current[index].style.color = 'rgba(0, 0, 0, 0.26)';
        myRefs.current[index].style.borderColor = 'rgba(0, 0, 0, 0.26)';

        await initiateFolderGeneration(downUrl)
            .then((downloadUrl) => {
                constCheckFolder(downloadUrl, projectName)
                    .then(
                        (boolean) => {
                            if (boolean) {
                                myRefs.current[index].style.backgroundColor = 'rgba(25, 118, 210, 0.04)';
                                myRefs.current[index].style.color = 'rgb(25, 118, 210)';
                                myRefs.current[index].style.borderColor = 'rgb(25, 118, 210)';
                                myRefs.current[index].style.disabled = false;
                                myLoadingRefs.current[index].style.display = 'none';
                            } else {
                                myRefs.current[index].style.backgroundColor = 'rgba(25, 118, 210, 0.04)';
                                myRefs.current[index].style.color = 'rgb(25, 118, 210)';
                                myRefs.current[index].style.borderColor = 'rgb(25, 118, 210)';
                                myRefs.current[index].style.disabled = false;
                                myLoadingRefs.current[index].style.display = 'none';
                            }
                        },
                    );
            });
    };

    const tableHeaders = [];
    for (const columnsKey in Columns) {
        const column = Columns[columnsKey];
        const isColumnEnabled = !hiddenColumns.includes(column);

        if (isColumnEnabled) {
            tableHeaders.push(column);
        }
    }

    const convertToCells = (row, rowIndex) => [
        {
            isDisabled: hiddenColumns.includes(Columns.projectName),
            data: row.projectName || 'N/A',
        },
        {
            isDisabled: hiddenColumns.includes(Columns.folderId),
            data: row.folderId
                ? (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '65px' }}>
                            {row.folderId}
                        </div>
                        <UrlButton
                            buttonStyles={{
                                link: { width: '32px', height: '32px' },
                            }}
                            description={`Copy to clipboard "${row.folderId}"`}
                            action={UrlActionType.copyToClipboard}
                            url={row.folderId}
                        />
                    </div>
                )
                : 'None',
        },
        {
            isDisabled: hiddenColumns.includes(Columns.titlesAmount),
            align: 'center',
            data: row.numberOfTitles || 'None',
        },
        {
            isDisabled: hiddenColumns.includes(Columns.username),
            data: row.username || 'None',
        },
        {
            isDisabled: hiddenColumns.includes(Columns.matterNumber),
            data: row.matterNumber || 'None',
        },
        {
            isDisabled: hiddenColumns.includes(Columns.createdAt),
            style: { minWidth: '90px' },
            data: datetimeToFormattedString(row.createdAt, 'None'),
        },
        {
            isDisabled: hiddenColumns.includes(Columns.lastOpenedAt),
            style: { minWidth: '90px' },
            data: datetimeToFormattedString(row.lastOpenedAt, 'None'),
        },
        {
            isDisabled: hiddenColumns.includes(Columns.lastUpdatedAt),
            style: { minWidth: '90px' },
            data: datetimeToFormattedString(row.lastUpdatedAt, 'None'),
        },
        {
            isDisabled: hiddenColumns.includes(Columns.download),
            align: 'center',
            data: (
                <Box sx={{ m: 1, position: 'relative' }}>
                    <Tooltip title="Download">
                        <IconButton
                            id={'button' + rowIndex}
                            ref={(el) => (myRefs.current[rowIndex] = el)}
                            onClick={(e) => handleDownload(row.attachments, row.projectName, e.target.id, rowIndex)}
                        >
                            <DownloadIcon />
                        </IconButton>
                    </Tooltip>
                    <CircularProgress
                        size={24}
                        ref={(el) => (myLoadingRefs.current[rowIndex] = el)}
                        sx={{
                            color: green[500],
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                            display: 'none',
                        }}
                    />
                </Box>
            ),
        },
        {
            isDisabled: hiddenColumns.includes(Columns.changeOwnership),
            align: 'center',
            data: (
                <Tooltip title="Change ownership">
                    <IconButton
                        onClick={() => {
                            setSelectedRowEmail(projectsOwnerEmail || row.username);
                            setSelectedProjectId(row.folderId);
                            setIsChangeEmailPopupOpen(true);
                        }}
                    >
                        <PublishedWithChangesIcon />
                    </IconButton>
                </Tooltip>
            ),
        },
        {
            isDisabled: hiddenColumns.includes(Columns.inspect),
            align: 'center',
            data: (
                <Tooltip title="Inspect">
                    <IconButton
                        onClick={() => {
                            setSelectedProjectId(row.folderId);
                            setIsInspectPopupOpen(true);
                        }}
                    >
                        <ZoomInIcon />
                    </IconButton>
                </Tooltip>
            ),
        },
        {
            isDisabled: hiddenColumns.includes(Columns.audit),
            align: 'center',
            data: (
                <Tooltip title="Audit">
                    <IconButton
                        onClick={() => {
                            setSelectedProjectId(row.folderId);
                            setIsAuditPopupOpen(true);
                        }}
                    >
                        <InventoryIcon />
                    </IconButton>
                </Tooltip>
            ),
        },
        {
            isDisabled: hiddenColumns.includes(Columns.generate),
            align: 'center',
            data: (
                <Tooltip title="Generate">
                    <IconButton
                        onClick={() => {
                            setSelectedProjectId(row.folderId);
                            setIsGeneratePopupOpen(true);
                        }}
                    >
                        <SupportAgentIcon />
                    </IconButton>
                </Tooltip>
            ),
        },
        {
            isDisabled: hiddenColumns.includes(Columns.apps),
            data: row.apps || 'None',
        },
    ];

    const tableRows = records.map((row, rowIndex) => ({
        cells: convertToCells(row, rowIndex)
            .filter((column) => !column.isDisabled),
    }));

    return (
        <>
            {
                isLoading
                    ? (<SkeletonComponent />)
                    : (
                        records.length > 0
                            ? (
                                <AvlTable
                                    headers={tableHeaders}
                                    rows={tableRows}
                                    page={page}
                                    pageCount={pageCount}
                                    onPageChange={onPageChange}
                                />
                            )
                            : (
                                <Stack sx={{ width: '100%' }} spacing={2}>
                                    <Alert severity="warning">Records not found!</Alert>
                                </Stack>
                            )
                    )
            }
            <AvlModal
                isOpen={isChangeEmailPopupOpen && selectedProjectId}
                onClose={handleCloseChangeEmailPopup}
                title="Edit email"
                maxWidth="450px"
            >
                <ChangeEmail
                    refreshRecords={onRefreshRecords}
                    projectsCurrentUser={selectedRowEmail}
                    selectedProjectId={selectedProjectId}
                    onClose={handleCloseChangeEmailPopup}
                />
            </AvlModal>
            <AvlModal
                isOpen={isInspectPopupOpen && selectedProjectId}
                onClose={handleCloseInspect}
                title="Documents"
                maxWidth="2000px"
            >
                <FileInformation
                    folderId={selectedProjectId}
                    onClose={handleCloseInspect}
                />
            </AvlModal>
            <AvlModal
                isOpen={isAuditPopupOpen && selectedProjectId}
                onClose={handleCloseAudit}
                title="Folder Audits"
                maxWidth="2000px"
            >
                <FolderAudits
                    folderId={selectedProjectId}
                    onClose={handleCloseAudit}
                />
            </AvlModal>
            <AvlModal
                isOpen={isGeneratePopupOpen}
                onClose={handleCloseGenerate}
                title="Available Reports"
                maxWidth="2000px"
            >
                <GenerateFolderReports
                    folderId={selectedProjectId}
                    onClose={handleCloseGenerate}
                />
            </AvlModal>
        </>
    );
}

ProjectsTable.propTypes = {
    records: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    rows: PropTypes.array.isRequired,
    hiddenColumns: PropTypes.array,
    pageCount: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    onRefreshRecords: PropTypes.func.isRequired,
    projectsOwnerEmail: PropTypes.string,
};
