import {
    ADMIN,
    AUTH_FAIL,
    AUTH_LOGOUT,
    AUTH_LOGOUT_START,
    AUTH_START,
    AUTH_SUCCESS,
    CONSOLE,
    SIGNIN_ERROR
} from './AuthenticationTypes.jsx';
import firebase from '../../config';
import { toast } from 'react-toastify';
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { getUserInfo } from '../../Services/Search.services';


const handleAuthFail = (dispatch, message) => {
    dispatch({
        type: AUTH_FAIL,
        payload: message
    });
    // handle error (e.g., display toast, show alert)
    toast.error(message);
};


// Signing in with firebase
export const signInToFirebase = (email, password) => async (dispatch) => {
    dispatch({ type: AUTH_START });
    const authentication = getAuth(firebase);

    try {
        const response = await signInWithEmailAndPassword(authentication, email, password);
        let currentUser = response.user;
        const idTokenResult = await authentication.currentUser.getIdTokenResult();
        if (idTokenResult.claims.console) {
            const userInfoResponse = await getUserInfo(idTokenResult.claims.email);
            if (userInfoResponse.status === 200) {
                dispatch({
                    type: AUTH_SUCCESS,
                    payload: idTokenResult.token
                });
                idTokenResult.claims.console ? dispatch({ type: CONSOLE }) : null;
                idTokenResult.claims.admin ? dispatch({ type: ADMIN }) : null;
            } else {
                // access denied
                // handle error
                handleAuthFail(dispatch, 'Access Denied');
            }
        } else {
            // access denied
            // handle error
            handleAuthFail(dispatch, 'Invalid login credentials');
        }
    } catch (err) {
        console.log('err', err);
        // handle error
        dispatch({ type: SIGNIN_ERROR, payload: 'Invalid login credentials' });
    }
};
export const signOutFromFirebase = () => async (dispatch) => {
    const authentication = getAuth(firebase);
    try {
        await signOut(authentication);
        dispatch({
            type: AUTH_LOGOUT_START,
        });
    } catch (error) {
        // handle error
        console.error('Failed to sign out', error);
    }
    // dispatch authCheckState
    dispatch(authCheckState());
};

export const authCheckState = () => async (dispatch) => {
    dispatch({ type: AUTH_START });
    try {
        const authentication = getAuth(firebase);
        authentication.onAuthStateChanged(async (user) => {
            if (user) {
                try {
                    const response = await getUserInfo(user.email);
                    if (response.status === 200) {
                        dispatch({
                            type: AUTH_SUCCESS,
                            payload: user.accessToken,
                        });
                    } else {
                        dispatch({
                            type: AUTH_LOGOUT,
                        });
                    }
                } catch (error) {
                    dispatch({
                        type: AUTH_LOGOUT,
                    });
                }

                try {
                    const idTokenResult = await user.getIdTokenResult();
                    if (idTokenResult.claims.console) {
                        dispatch({ type: CONSOLE });
                    }
                    if (idTokenResult.claims.admin) {
                        dispatch({ type: ADMIN });
                    }
                } catch (error) {
                    // Handle error if needed
                }
            } else {
                dispatch({
                    type: AUTH_LOGOUT,
                });
            }
        });
    } catch (error) {
        // Handle error if needed
    }
};
