import axios from 'axios';
import { toast } from 'react-toastify';
import { getAuth } from 'firebase/auth';
import { authCheckState } from '../Store/Actions/AuthenticationActions';
import store from '../store';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    validateStatus: function (status) {
        return status >= 200 && status < 400;
    },
    // cancelToken: source.token,
});

axiosInstance.interceptors.request.use(function (config) {
    const auth = getAuth();
    // const token  = store.getState().auth.token;
    // const token = localS torage.getItem('authToken');
    config.headers.Authorization = auth?.currentUser?.accessToken ? `Bearer ${auth?.currentUser?.accessToken}` : null;
    return config;
});

let retryCount = 0;
const maxRetries = 3;
const retryDelay = 1000; // Delay in ms
axiosInstance.interceptors.response.use((response) => {
    return response;
}, (error) => {
    if (axios.isCancel(error)) {
        return Promise.reject(error);
    } else if (error.response.status === 401) {
        store.dispatch(authCheckState());
    } else if (error.response.status === 502) {
        toast.error('You are having connection problems', { toastId: 'connection' });
    } else if (error.response) {
        switch (error.response.status) {
            case 401:
                store.dispatch(authCheckState());
                return Promise.reject(error);
            case 502:
                if (retryCount < maxRetries) {
                    retryCount++;
                    toast.warning(`Connection problem, retrying... (${retryCount}/${maxRetries})`, { toastId: 'retryConnection' });
                    return new Promise((resolve) => setTimeout(() => resolve(axios(error.config)), retryDelay));
                } else {
                    retryCount = 0; // Reset counter
                    toast.error('You are having connection problems', { toastId: 'connection' });
                    return Promise.reject(error);
                }
            // default:
            //     toast.error('An unexpected error occurred', {toastId: 'unexpectedError'});
            //     break;
        }
        return Promise.reject(error);
    } else {
        toast.error('You are having problems with your access points', { toastId: 'connectionAccess' });
        return Promise.reject(error);
    }
});

export default axiosInstance;
