import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { Pagination } from '@mui/material';
import PropTypes from 'prop-types';

/**
 *
 * @param headers is a list of header names (strings)
 * @param rows is a list of AvlTableCell objects lists.
 *
 * type AvlTableCell {
 *      align: string,
 *      style: {},
 *      data: {},
 * }
 * @param pageCount is a total count of pages
 * @param page is an index of the current page
 * @param onPageChange is a callback function on page change event
 */
export function AvlTable({ headers, rows, pageCount, page, onPageChange }) {
    return (
        <>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            {
                                headers.map((header, headerIndex) => (
                                    <TableCell key={headerIndex}>{header}</TableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            rows.map((row, rowIndex) => (
                                <TableRow key={rowIndex}>
                                    {
                                        row.cells.map((cell, cellIndex) => (
                                            <TableCell
                                                key={`${rowIndex}:${cellIndex}`}
                                                align={cell.align}
                                                style={cell.style}
                                            >
                                                {cell.data}
                                            </TableCell>
                                        ))
                                    }
                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <div style={{ marginTop: '20px' }}>
                <Pagination
                    showFirstButton
                    showLastButton
                    count={pageCount}
                    page={page}
                    siblingCount={1}
                    boundaryCount={1}
                    variant="outlined"
                    shape="rounded"
                    onChange={onPageChange}
                />
            </div>
        </>
    );
}

Table.propTypes = {
    headers: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
    pageCount: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
};
