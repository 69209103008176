import * as actionTypes from '../Actions/AuthenticationTypes';

const initialState = {
    isAuthenticated: null,
    isLoading: false,
    isAdmin: null,
    isConsole: null,
    token: null,
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START:
            return {
                ...state,
                isAuthenticated: null,
                isLoading: true,
                isAdmin: null,
                isConsole: null,
                token: null
            };
        case actionTypes.AUTH_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                isLoading: false,
                token: action.payload
            };
        case actionTypes.AUTH_FAIL:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                token: null
            };
        case actionTypes.AUTH_LOGOUT_START:
            return {
                ...state,
                isAuthenticated: null,
                isLoading: true,
                isAdmin: null,
                isConsole: null,
                token: null
            };
        case actionTypes.AUTH_LOGOUT:
            return {
                ...state,
                isLoading: false,
                isAuthenticated: false,
                isAdmin: null,
                isConsole: null,
                token: null
            };
        case actionTypes.ADMIN:
            return {
                ...state,
                isAdmin: true
            };
        case actionTypes.CONSOLE:
            return {
                ...state,
                isConsole: true
            };
        default:
            return state;
    }
};

// Selectors
// export const getIsAuthenticated = (state) => state.auth.isAuthenticated;
export const getIsAuthenticated = (state) => state.auth.isAuthenticated;

export default authReducer;




