import React, { useEffect, useMemo, useState } from 'react';
import Button from '@mui/material/Button';
import { addInvoicing, getOrganizationInfo, updateInvoicing } from '../../../Services/Search.services';
import SkeletonComponent from '../../SkeletonComponent';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { AvlTextField } from '../Inputs/FieldText/AvlTextField';
import { AvlAutocomplete } from '../Inputs/Autocomplete/AvlAutocomplete';
import { AvlDatePickerField } from '../Inputs/DatePicker/AvlDatePickerField';
import {
    bodyTplFrequentOptions,
    BodyTplFrequentOptionsKey,
} from '../../../Constants/BodyTplContent.constant';
import { AvlChip } from '../Inputs/AvlChip/AvlChip';
import { dateFormat } from '../../../Constants/Date.constant';
import { addZeroTimeToIso, extractDateFromIso } from '../../../Utils/Utils';
import { AvlSwitch } from '../Inputs/Switch/AvlSwitch';
import styles from './InvoicingForm.module.css';

const specData = `{
    "columns": [
        "date",
        "location",
        "title_no",
        "oo_type",
        "land_reg_ref",
        "matter_number",
        "fee_quoted",
        "cost",
        "currency",
        "user"
    ],
    "headers": [
        "Invoice Date",
        "Blank",
        "Title",
        "Transaction Type",
        "Blank",
        "Client/Matter",
        "Blank",
        "Cost",
        "Currency",
        "Username"
    ],
    "defaults": {
        "currency": "GBP",
        "location": "",
        "land_reg_ref": "",
        "fee_quoted": ""
    },
    "date-format": "%d-%B-%y",
    "type-format": {
        "title-register": "Title Register",
        "title-plan": "Title Plan",
        "title-report": "Avail AI Analysis",
        "digital-title-plan": "Digital Title Plan",
        "digital-mapping": "Digital Mapping"
    }
}
`;

const subjectTplFrequentOptions = [
    'Avail Monthly Report',
    'Avail - Disbursements CSV',
    'Avail - Report Chargeback CSV',
    'Avail - Disbursements CSV and Report Chargeback CSV',
    'Avail Trial Invoice',
];

const optionsForKind = [
    'HMLR-3RD.CSV+PRJ.CSV+HMLR-3RD.XERO.DD',
    'HMLR-3RD.CSV+HMLR-3RD.XERO.DD',
    'HMLR-3RD.CSV+HMLR-3RD.XERO',
    'HMLR-3RD.CSV+PRJ.CSV',
    'HMLR.CSV+HMLR.XERO',
    'HMLR-3RD.XERO.DD',
    'HMLR-3RD.XERO',
    'HMLR-3RD.CSV',
    'HMLR.XERO',
    'PRJ.CSV',
    'MTHLY',
];

const KindAccountMap = {
    'HMLR-3RD.CSV+PRJ.CSV+HMLR-3RD.XERO.DD': '621-201',
    'HMLR-3RD.CSV+HMLR-3RD.XERO.DD': '621-201',
    'HMLR-3RD.CSV+HMLR-3RD.XERO': '623-201',
    'HMLR-3RD.CSV+PRJ.CSV': '621-201',
    'HMLR.CSV+HMLR.XERO': '623',
    'HMLR-3RD.XERO.DD': '621-201',
    'HMLR-3RD.XERO': '623-201',
    'HMLR-3RD.CSV': '621-201',
    'HMLR.XERO': '623',
    'PRJ.CSV': '201',
    'MTHLY': '201',
};

const FieldKeys = {
    subjectTpl: 'subjectTpl',
    refPrefix: 'refPrefix',
    account: 'account',
    emailsTo: 'emailsTo',
    emailsCc: 'emailsCc',
    frequency: 'frequency',
    dayOfMonth: 'dayOfMonth',
    startInvoicingAt: 'startInvoicingAt',
    kind: 'kind',
    spec: 'spec',
    bodyTpl: 'bodyTpl',

    // For edit form
    id: 'id',
    enabled: 'enabled',
};

const FieldOptions = {
    [FieldKeys.subjectTpl]: {
        label: 'Subject TPL',
        isRequired: true,
        isManualEditOn: true,
        isMultiple: false,
        type: 'autocomplete',
        options: subjectTplFrequentOptions,
    },
    [FieldKeys.refPrefix]: {
        label: 'Reference Prefix',
        isRequired: true,
        default: 'X-DISBURSEMENT-{counter}',
        type: 'text',
    },
    [FieldKeys.kind]: {
        label: 'Kind',
        isRequired: true,
        isMultiple: false,
        type: 'autocomplete',
        options: optionsForKind,
    },
    [FieldKeys.account]: {
        label: 'Account',
        isRequired: true,
        type: 'text',
    },
    [FieldKeys.emailsTo]: {
        label: 'Emails to',
        isRequired: true,
        type: 'text',
    },
    [FieldKeys.emailsCc]: {
        label: 'Emails CC',
        isRequired: false,
        type: 'text',
    },
    [FieldKeys.frequency]: {
        label: 'Frequency',
        isRequired: true,
        type: 'number',
        step: '1',
    },
    [FieldKeys.dayOfMonth]: {
        label: 'Day of month invoicing',
        isRequired: true,
        type: 'number',
        step: '1',
        default: 0,
    },
    [FieldKeys.startInvoicingAt]: {
        label: 'Start invoicing at',
        isRequired: true,
        type: 'date',
        format: dateFormat,
    },
    [FieldKeys.bodyTpl]: {
        label: 'Body TPL',
        isRequired: true,
        isMultiline: true,
        type: 'text',
    },
    [FieldKeys.spec]: {
        label: 'Spec',
        isRequired: true,
        isMultiline: true,
        type: 'text',
    },

    // For edit mode
    [FieldKeys.enabled]: {
        label: 'Is invoicing enabled',
        isRequired: false,
        isVisibleInAddMode: false,
        type: 'checkbox',
    },
};

export default function InvoicingForm({ orgName, onSave, initialValues = {}, isEditMode = false }) {
    const dayOfOneWeekAgo = useMemo(() => {
        const today = new Date();
        const lastWeek = new Date();
        lastWeek.setDate(today.getDate() - 7);

        return lastWeek;
    }, []);
    const formikInitialValues = useMemo(() => {
        const values = {
            [FieldKeys.subjectTpl]: initialValues[FieldKeys.subjectTpl] || '',
            [FieldKeys.refPrefix]: initialValues[FieldKeys.refPrefix] ?? FieldOptions[FieldKeys.refPrefix].default ?? '',
            [FieldKeys.account]: initialValues[FieldKeys.account] ?? '',
            [FieldKeys.emailsTo]: initialValues[FieldKeys.emailsTo] ?? '',
            [FieldKeys.emailsCc]: initialValues[FieldKeys.emailsCc] ?? '',
            [FieldKeys.frequency]: initialValues[FieldKeys.frequency] ?? FieldOptions[FieldKeys.frequency].default ?? 1,
            [FieldKeys.dayOfMonth]: initialValues[FieldKeys.dayOfMonth] ?? FieldOptions[FieldKeys.dayOfMonth].default ?? 1,
            [FieldKeys.startInvoicingAt]: new Date(extractDateFromIso(initialValues[FieldKeys.startInvoicingAt]) ?? dayOfOneWeekAgo),
            [FieldKeys.kind]: initialValues[FieldKeys.kind] || '',
            [FieldKeys.spec]: initialValues[FieldKeys.spec] ?? specData,
            [FieldKeys.bodyTpl]: initialValues[FieldKeys.bodyTpl] ?? bodyTplFrequentOptions[BodyTplFrequentOptionsKey.disbursementsChargebackXero].content,
        };

        if (isEditMode) {
            values[FieldKeys.id] = initialValues[FieldKeys.id];
            values[FieldKeys.enabled] = initialValues[FieldKeys.enabled];
        }

        return values;
    }, [dayOfOneWeekAgo, initialValues, isEditMode]);
    const [isLoading, setIsLoading] = useState(true);

    const filterEditedValues = (values, initialValues) => {
        const editedValues = {};

        Object.keys(values)
            .forEach((key) => {
                if (values[key] !== initialValues[key]) {
                    editedValues[key] = values[key];
                }
            });

        return editedValues;
    };

    const validationSchema = yup.object({
        [FieldKeys.subjectTpl]: yup
            .string('Please write the subject TPL (string)')
            .required('Display name is required'),
        [FieldKeys.refPrefix]: yup
            .string('Please write the reference prefix (string)')
            .required('Reference prefix is required'),
        [FieldKeys.account]: yup
            .string('Please write the account (string)')
            .required('Account is required'),
        [FieldKeys.emailsTo]: yup
            .string('Please write the (To)emails (string)')
            .email('Please write the (To)emails (string)')
            .required('(To)emails is required'),
        [FieldKeys.emailsCc]: yup
            .string('Please write the (Cc)emails (string)')
            .email('Please write the (Cc)emails (string)')
            .optional(),
        [FieldKeys.frequency]: yup
            .number('Please write the frequency (number)')
            .required('Frequency is required'),
        [FieldKeys.dayOfMonth]: yup
            .number('Please write the Day of Month Invoicing (number 0-31)')
            .positive('Day of Month Invoicing is a positive number (0-31)')
            .lessThan(32, 'Day of Month Invoicing must be 31 or less')
            .min(0, 'Day of Month Invoicing must be 0 or positive')
            .required('Day of Month Invoicing is required'),
        [FieldKeys.startInvoicingAt]: yup
            .date('Please write the start invoicing at (date)')
            .required('Start invoicing at is required'),
        [FieldKeys.kind]: yup
            .string('Please select at least one acceptable document')
            .required('Acceptable documents is required'),
        [FieldKeys.spec]: yup
            .string('Please write the spec (string)')
            .required('Spec is required'),
        [FieldKeys.bodyTpl]: yup
            .string('Please write the body TPL (string)')
            .required('Body TPL is required'),
    });
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: formikInitialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const newValues = { ...values };
            
            if (isEditMode) {
                const editedValues = filterEditedValues(newValues, formik.initialValues);

                if (editedValues[FieldKeys.startInvoicingAt]) {
                    editedValues[FieldKeys.startInvoicingAt] = addZeroTimeToIso(editedValues[FieldKeys.startInvoicingAt].toISOString());
                }

                updateInvoicing(formik.values[FieldKeys.id], editedValues)
                    .then((successful) => {
                        if (successful) {
                            onSave?.();
                            formik.resetForm();
                        }
                    });
            } else {
                console.log(addZeroTimeToIso(newValues[FieldKeys.startInvoicingAt].toISOString()));
                newValues['organisation'] = orgName;
                newValues[FieldKeys.startInvoicingAt] = addZeroTimeToIso(newValues[FieldKeys.startInvoicingAt].toISOString());

                if (!newValues[FieldKeys.emailsCc]) {
                    delete newValues[FieldKeys.emailsCc];
                }

                addInvoicing(newValues)
                    .then((response) => {
                        if (response.status === 201) {
                            onSave?.();
                            formik.resetForm();
                        }
                    });
            }
        },
    });

    if (formik.dirty) {
        window.onbeforeunload = () => true;
    } else {
        window.onbeforeunload = undefined;
    }

    useEffect(() => {
        getOrganizationInfo(orgName)
            .then((res) => {
                if (res) {
                    formik.setFieldValue(FieldKeys.startInvoicingAt, formik.initialValues[FieldKeys.startInvoicingAt]);
                    setIsLoading(false);
                } else {
                    toast.error('Something went wrong! Try to reload the page', { autoClose: 6000 });
                }
            });
        // Return unsubscribe;
    }, [orgName]);

    useEffect(() => {
        toast.warn('It is always good to ask if you are not sure how to do!', { toastId: 'notSure', autoClose: 10000 });
    }, []);

    useEffect(() => {
        // TODO: it isn't a solution at all, but this will hide the error for the development time
        const errorHandler = (e) => {
            const isResizeObserverLoopError = e.message.includes(
                'ResizeObserver loop completed with undelivered notifications' ||
                'ResizeObserver loop limit exceeded',
            );

            if (isResizeObserverLoopError) {
                const resizeObserverErrorOverlay = document.getElementById(
                    'webpack-dev-server-client-overlay',
                );

                if (resizeObserverErrorOverlay) {
                    resizeObserverErrorOverlay.style.display = 'none';
                }
            }
        };
        window.addEventListener('error', errorHandler);

        return () => {
            window.removeEventListener('error', errorHandler);
        };
    }, []);

    const handleAutocompleteChange = (fieldKey, value) => {
        if (fieldKey === FieldKeys.kind) {
            const updatedValueOfAccountField = KindAccountMap[value];

            if (updatedValueOfAccountField) {
                formik.setFieldValue(FieldKeys.account, updatedValueOfAccountField);
            }
        }

        formik.setFieldValue(fieldKey, value);
    };

    const textField = (fieldKey) => {
        const params = FieldOptions[fieldKey];
        const isError = formik.touched[fieldKey] ? !!formik.errors[fieldKey] : false;
        const helperText = formik.touched[fieldKey] && formik.errors[fieldKey];

        return (
            <AvlTextField
                className={styles.field}
                required={params.isRequired}
                label={params.label}
                multiline={params.isMultiline}
                name={fieldKey}
                type={params.type}
                step={params.step}
                value={formik.values[fieldKey] ?? ''}
                onChange={formik.handleChange}
                error={isError}
                helperText={helperText}
            />
        );
    };

    const dateField = (fieldKey) => {
        const params = FieldOptions[fieldKey];
        const isError = formik.touched[fieldKey] ? !!formik.errors[fieldKey] : false;
        const helperText = formik.touched[fieldKey] && formik.errors[fieldKey];

        return (
            <AvlDatePickerField
                className={`${styles.field} ${styles.dateField}`}
                required={params.isRequired}
                format={params.format}
                label={params.label}
                name={fieldKey}
                value={formik.values[fieldKey] ?? ''}
                error={isError}
                helperText={helperText}
                onChange={(value) => formik.setFieldValue(fieldKey, value)}
            />
        );
    };

    const autocompleteField = (fieldKey) => {
        const params = FieldOptions[fieldKey];
        const isError = formik.touched[fieldKey] ? !!formik.errors[fieldKey] : false;
        const helperText = formik.touched[fieldKey] && formik.errors[fieldKey];

        return (
            <AvlAutocomplete
                multiple={params.isMultiple}
                freeSolo={params.isManualEditOn}
                className={styles.field}
                required={params.isRequired}
                label={params.label}
                name={fieldKey}
                options={params.options}
                value={formik.values[fieldKey]}
                onInputChange={(e, value) => handleAutocompleteChange(fieldKey, value)}
                error={isError}
                helperText={helperText}
            />
        );
    };

    const bodyTplOptions = () => {
        const handleBodyTplOptionSelect = (content) => {
            formik.setFieldValue(FieldKeys.bodyTpl, content);
        };

        return Object.values(bodyTplFrequentOptions)
            .map(({ displayName, content, key, color }) => (
                <AvlChip
                    id={key}
                    key={`body-tpl-option-${key}`}
                    label={displayName}
                    color={'#f3f3f3'}
                    onClick={() => handleBodyTplOptionSelect(content)}
                />
            ));
    };

    const switchField = (fieldKey) => {
        const params = FieldOptions[fieldKey];
        const checked = formik.values[fieldKey] ? formik.values[fieldKey] : false;
        const isVisible = !isEditMode && params.isVisibleInAddMode || isEditMode;

        return isVisible && (
            <AvlSwitch
                label={params.label}
                name={fieldKey}
                value={formik.values[fieldKey]}
                onChange={formik.handleChange}
                checked={checked}
            />
        );
    };

    return isLoading
        ? (<SkeletonComponent />)
        : (
            <div className={styles.form}>
                <div className={styles.controlsContainer}>
                    {formik.dirty
                        && (
                            <>
                                <Button
                                    className="button"
                                    variant="outlined"
                                    size="small"
                                    color="primary"
                                    disabled={isLoading}
                                    onClick={() => formik.resetForm()}
                                >
                                    Reset
                                </Button>
                                <Button
                                    className="button"
                                    variant="contained"
                                    size="small"
                                    color="primary"
                                    disabled={isLoading}
                                    onClick={() => {
                                        formik.submitForm()
                                            .catch(console.error);
                                    }}
                                >
                                    Save
                                </Button>
                            </>
                        )}
                </div>
                <div className={styles.sections}>
                    <div className={`${styles.section} ${styles.shortFields}`}>
                        <div className={`${styles.fieldsRow} ${styles.fieldsRowWrap}`}>
                            {autocompleteField(FieldKeys.subjectTpl)}
                            {switchField(FieldKeys.enabled)}
                        </div>
                        {autocompleteField(FieldKeys.kind)}
                        <div className={styles.fieldsRow}>
                            {textField(FieldKeys.account)}
                            {textField(FieldKeys.refPrefix)}
                        </div>
                        <div className={styles.fieldsRow}>
                            {textField(FieldKeys.frequency)}
                            {textField(FieldKeys.dayOfMonth)}
                            {dateField(FieldKeys.startInvoicingAt)}
                        </div>
                        <div className={styles.fieldsRow}>
                            {textField(FieldKeys.emailsTo)}
                            {textField(FieldKeys.emailsCc)}
                        </div>
                    </div>
                    <div className={`${styles.section} ${styles.bodyTplBlock}`}>
                        <div className={styles.fieldResetButtonBlock}>
                            {formik.getFieldMeta(FieldKeys.bodyTpl).value !== formik.getFieldMeta(FieldKeys.bodyTpl).initialValue && (
                                <Button
                                    className="button"
                                    variant="outlined"
                                    size="small"
                                    color="primary"
                                    disabled={isLoading}
                                    onClick={() => formik.setFieldValue(FieldKeys.bodyTpl, formik.initialValues[FieldKeys.bodyTpl])}
                                >
                                    Set default
                                </Button>
                            )}
                        </div>
                        <div className={styles.bodyTplOptions}>
                            <p className={styles.bodyTplOptionsLabel}>Body TPL templates</p>
                            {bodyTplOptions()}
                        </div>
                        {textField(FieldKeys.bodyTpl)}
                    </div>
                    <div className={`${styles.section} ${styles.specBlock}`}>
                        <div className={styles.fieldResetButtonBlock}>
                            {formik.getFieldMeta(FieldKeys.spec).value !== formik.getFieldMeta(FieldKeys.spec).initialValue && (
                                <Button
                                    className="button"
                                    variant="outlined"
                                    size="small"
                                    color="primary"
                                    disabled={isLoading}
                                    onClick={() => formik.setFieldValue(FieldKeys.spec, formik.initialValues[FieldKeys.spec])}
                                >
                                    Set default
                                </Button>
                            )}
                        </div>
                        {textField(FieldKeys.spec)}
                    </div>
                </div>
            </div>
        );
}
