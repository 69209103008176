import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { NavLink } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import firebase from '../../config';
import { useState } from 'react';

let btnDisable = false;
export default function ResetPassword() {
    const [visibleError, setIsVisibleError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    setTimeout(function () {
        setIsVisibleError(false);
    }, 7000);

    btnDisable = true;
    // let navigate = useNavigate();
    const handleResetPassword = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        //*********** firebase sign in  ***********
        const authentication = getAuth(firebase);
        sendPasswordResetEmail(authentication, data.get('email'))
            .then((response) => {
                //navigate('/app');
                alert('Password reset link sent!');
            })
            .catch((error) => {
                btnDisable = false;
                setIsVisibleError(true);
                if (error.code === 'auth/wrong-password') {
                    setErrorMsg('Wrong email or password');
                }
                if (error.code === 'auth/user-not-found') {
                    setErrorMsg('Please check the Email');
                    //toast.error('Please check the Email');
                }
            });
    };

    return (
        <>
            <Typography component="h1" variant="h5">
                Request reset password.
            </Typography>
            {/* error display */}
            {visibleError === true && (
                <Stack sx={{ width: '100%' }} spacing={2}>
                    <Alert severity="error">{errorMsg}</Alert>
                </Stack>
            )}
            <Box component="form" noValidate onSubmit={handleResetPassword} sx={{ mt: 1 }}>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                />
                <FormControlLabel
                    style={{ visibility: 'hidden' }}
                    control={<Checkbox value="remember" color="primary" />}
                    label="Remember me"
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    disabled={!btnDisable}
                >
                    Request
                </Button>
                <Grid container>
                    <Grid item xs>
                        <NavLink to="/login" variant="body">
                            Back to login?
                        </NavLink>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}
