import { Header } from '../../../../Shared/Header/Header';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AvlSwitch } from '../../../../Shared/Inputs/Switch/AvlSwitch';
import styles from './OrgDetailsTab.module.css';
import Button from '@mui/material/Button';
import { getOrganizationInfo, updatePlan } from '../../../../../Services/Search.services';
import axios from 'axios';
import SkeletonComponent from '../../../../SkeletonComponent';
import { OrgForm } from '../../../../Shared/OrgForm/OrgForm';
import OutputSpecification from './OrgDetailsContent/OutputSpecification/OutputSpecification';
import { AvlModal } from '../../../../Shared/AvlModal/AvlModal';

export function OrgDetailsTab({ orgName }) {
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [orgDetails, setOrgDetails] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isOutputSpecificationOpen, setIsOutputSpecificationOpen] = useState(false);
    const isAdmin = useSelector((state) => state.auth.isAdmin);

    useEffect(() => {
        const { cancel, token: cancelToken } = axios.CancelToken.source();

        setIsEditable(false);
        getOrganizationInfo(orgName, cancelToken)
            .then((res) => {
                if (!res) return;

                setOrgDetails(mapOrgDetails(res));
                setIsLoading(false);
            });

        return () => cancel();
    }, [orgName]);

    const mapOrgDetails = (details) => {
        const xeroId = details.xeroContact?.split('/')
            .pop();
        const isXeroIdExists = xeroId !== 'None';
        const restrictions = JSON.parse(details.matterNumberRestriction || '');

        return {
            displayName: details.name || details.displayName,
            xeroId: isXeroIdExists ? xeroId : '',
            oneTitleCost: details.oneTitleCost,
            dayOfMonthsInvoicing: details.dayOfMonthsInvoicing,
            matterNumberPattern: restrictions.matterNumber?.pattern,
            matterNumberErrorMessage: restrictions.matterNumber?.errorMessage,
            projectNamePattern: restrictions.projectName?.pattern,
            projectNameErrorMessage: restrictions.projectName?.errorMessage,
            apps: details.apps?.split(';') ?? ['titles'],
            acceptableDocuments: details.acceptableDocuments ?? [],
            titlesDefaultExtras: details.defaultExtras ?? [],
            titleRegisterSchedules: details.schedules ?? [],
            isSchedulesEnabled: details.isSchedulesEnabled ?? false,
            isTrial: details.isTrial ?? false,
            isFlatOneTitleCost: details.isFlatOneTitleCost ?? false,
            isLandRegistryEnabled: details.landRegistry ?? false,

            // Additional fields
            directDebit: details.directDebit,
            dayOfMonthInvoicing: details.dayOfMonthInvoicing,
            hourlyRate: details.hourlyRate,
            monthlyFee: details.monthlyFee,
            subscriptionRenewalAt: details.subscriptionRenewalAt,
            subscriptionStartedAt: details.subscriptionStartedAt,
            tierName: details.tierName,
            trialEndedAt: details.trialEndedAt,
            trialStartedAt: details.trialStartedAt,
            xeroContact: details.xeroContact,
        };
    };

    const handleFormUpdate = (orgDetails) => {
        setIsLoading(true);

        updatePlan(orgName, orgDetails)
            .then(() => setIsEditable(false))
            .catch((error) => console.error(error))
            .finally(() => {
                getOrganizationInfo(orgName)
                    .then((res) => {
                        if (!res) return;

                        setOrgDetails(mapOrgDetails(res));
                    })
                    .finally(() => setIsLoading(false));
            });
    };

    return (
        <>
            <div className="header">
                <Header
                    title={isEditable ? 'Edit Organisation Details' : 'Organisation Details'}
                    actionButtons={[
                        isAdmin && (
                            <AvlSwitch
                                key="form"
                                label="Is the details editable"
                                disabled={isEditable && isFormDirty}
                                checked={isEditable}
                                onSwitch={setIsEditable}
                            />
                        ),
                        isAdmin && (
                            <Button
                                key="output-specification-button"
                                variant="contained"
                                onClick={() => setIsOutputSpecificationOpen(true)}
                            >
                                Output Specification
                            </Button>
                        ),
                    ]}
                />
            </div>
            <AvlModal
                isOpen={isOutputSpecificationOpen}
                onClose={() => setIsOutputSpecificationOpen(false)}
                title="Output Specification"
            >
                <OutputSpecification
                    organisation={orgName}
                    onSubmit={() => setIsOutputSpecificationOpen(false)}
                />
            </AvlModal>
            <div className={styles.buttonsAndForm}>
                {
                    isLoading
                        ? (<SkeletonComponent />)
                        : (
                            <OrgForm
                                isViewFieldsVisible
                                initialValues={orgDetails}
                                onSave={handleFormUpdate}
                                isEditable={isEditable}
                                onDirtyChange={setIsFormDirty}
                            />
                        )
                }
            </div>
        </>
    );
}
