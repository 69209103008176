import { Fragment, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Outlet, useNavigate } from 'react-router-dom';
import NavbarComponent from '../Header/NavbarComponent';
import { styled } from '@mui/system';

const OutletBox = styled(Box)(({ theme }) => {
    return {
        width: '100%',
        minHeight: '100vh',
        height: 'auto',
        background: '#efefef',
        boxSizing: 'border-box',
        padding: '70px 24px 24px 24px',
        [theme.breakpoints.down('sm')]: {
            padding: '70px 24px 24px 24px',
        },
    };
});

function Layout({ auth }) {
    const [mobileOpen, setMobileOpen] = useState(false);
    let navigate = useNavigate();

    useEffect(() => {
        if (auth.isConsole === false || auth.isAuthenticated === false) {
            navigate('/login');
        }
    }, [auth.isAuthenticated, auth.isConsole, navigate]);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleDrawerClose = () => {
        setMobileOpen(false);
    };

    return (
        <>
            <NavbarComponent handleDrawerToggle={handleDrawerToggle} />
            {/*
            <Sidenav
                mobileOpen={mobileOpen}
                handleDrawerClose={handleDrawerClose}
                handleDrawerToggle={handleDrawerToggle}
            /> */}
            <OutletBox>
                <Outlet />
            </OutletBox>
        </>
    );
}

export default Layout;
